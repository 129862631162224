import React from 'react';
import * as Styled from '../styles';

const FebRollover = () => (
    <>
        <li>
            Super 6 February Rollover
            <Styled.OrderedList>
                <li>
                    Between 22:15 on the 29th of January 2025 and 15:00 on the
                    22nd of February 2025, the Super 6 February Rollover will be
                    running. During this period, on Saturday rounds only, if a
                    jackpot is not won, the jackpot will roll over to the next
                    Saturday round. If the jackpot is won on any of these
                    Saturday rounds, the jackpot will return to &#163;250,000
                    for the following Saturday round. The dates included for
                    these rounds are listed below:
                </li>
                <li>
                    Rollover rounds:
                    <br />
                    Rollover Round 1: 22:15 29th January 2025 &#45; 15:00 1st
                    February 2025
                    <br />
                    Rollover Round 2: 18:45 2nd February 2025 &#45; 15:00 8th
                    February 2025
                    <br />
                    Rollover Round 3: 22:15 12th February 2025 &#45; 15:00 15th
                    February 2025
                    <br />
                    Rollover Round 4: 22:15 19th February 2025 &#45; 15:00 22nd
                    February 2025
                </li>
                <li>
                    Rollover round jackpot details:
                    <br />
                    Please see the following table for details on the potential
                    outcomes and related Jackpot Prizes (&#39;W&#39; denotes a
                    day where the Jackpot Prize is won, &#39;L&#39; denotes a
                    day when it is not won):
                    <Styled.TermsTable>
                        <table>
                            <tr>
                                <th>Outcome</th>
                                <th>Rollover Round 1</th>
                                <th>Rollover Round 2</th>
                                <th>Rollover Round 3</th>
                                <th>Rollover Round 4</th>
                            </tr>
                            <tr>
                                <td>L-L-L-L</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;750,000</td>
                                <td>&#163;1,000,000</td>
                            </tr>
                            <tr>
                                <td>L-L-L-W</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;750,000</td>
                                <td>&#163;1,000,000</td>
                            </tr>
                            <tr>
                                <td>L-L-W-W</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;750,000</td>
                                <td>&#163;250,000</td>
                            </tr>
                            <tr>
                                <td>L-L-W-L</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;750,000</td>
                                <td>&#163;250,000</td>
                            </tr>
                            <tr>
                                <td>W-L-L-L</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;750,000</td>
                            </tr>
                            <tr>
                                <td>W-L-L-W</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;750,000</td>
                            </tr>
                            <tr>
                                <td>W-L-W-L</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;250,000</td>
                            </tr>
                            <tr>
                                <td>W-L-W-W</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;250,000</td>
                            </tr>
                            <tr>
                                <td>L-W-W-L</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                            </tr>
                            <tr>
                                <td>L-W-L-W</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                            </tr>
                            <tr>
                                <td>L-W-L-L</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                            </tr>
                            <tr>
                                <td>L-W-W-W</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                            </tr>
                            <tr>
                                <td>W-W-W-W</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                            </tr>
                            <tr>
                                <td>W-W-W-L</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                            </tr>
                            <tr>
                                <td>W-W-L-L</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                            </tr>
                            <tr>
                                <td>W-W-L-W</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;250,000</td>
                                <td>&#163;500,000</td>
                            </tr>
                        </table>
                    </Styled.TermsTable>
                </li>
                <li>
                    If for any reason the featured rounds in the rollover listed
                    above are cancelled or the jackpot is reduced, the jackpot
                    will be deemed to not have been won and will continue to
                    roll over for the next round.
                </li>
                <li>
                    Following the 22nd of February 2025, rounds will adhere to
                    the standard jackpot amount of &#163;250,000 and will follow
                    the terms and conditions of the Jackpot Competition in
                    section 6.7.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default FebRollover;
